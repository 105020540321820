import React from "react"

import Layout from "./../layouts/Layout/Layout"
import TwoColumnSection from "../sections/TwoColumnSection/TwoColumnSection"
import FeaturesList from "../sections/FeaturesList/FeaturesList"
import HeroSubpage from "../sections/HeroSubpage/HeroSubpage"
import SupportOptions from "../components/SupportOptions/SupportOptions"

import Background from "../assets/images/hero_partner.jpg"

import IconGrowth from "../assets/svg/icon_growth.svg"
import IconCSR from "../assets/svg/icon_csr.svg"
import IconNetwork from "../assets/svg/icon_network.svg"
import IconExperts from "../assets/svg/icon_experts.svg"

const ParentPage = () => (
  <Layout>
    <HeroSubpage title="Dla Partnerów" backgroundImage={Background} />
    <FeaturesList
      title="Co Alkantara znaczy dla biznesu?"
      subtitle="Dzięki kompleksowej współpracy przedsiębiorców, managerów i szkoleniowców w ramach Programu Alkantara:"
      items={[
        {
          backgroundColor: "#15232F",
          svg: IconExperts,
          text: "Zwiększamy liczbę wartościowych specjalistów na rynku pracy",
        },
        {
          backgroundColor: "#15232F",
          svg: IconGrowth,
          text: "Wspieramy rozwój trójmiejskiego biznesu i promujemy ekspertów",
        },
        {
          backgroundColor: "#15232F",
          svg: IconNetwork,
          text:
            "Tworzymy wartościową sieć przedsiębiorców, szkoleniowców i mentorów ",
        },
        {
          backgroundColor: "#15232F",
          svg: IconCSR,
          text: "Działamy społecznie, skutecznie budując CSR firm partnerskich",
        },
      ]}
    />
    <TwoColumnSection
      heading="Jak można wspierać Program Alkantara?"
      article={
        "<p>Program Alkantara to inicjatywa <strong>Fundacji Inspirujące Przykłady</strong> kierowana do zdolnych studentów z województwa pomorskiego i nie tylko. Tworzą ją świetni specjaliści (między innymi przedsiębiorcy, managerowie, szkoleniowcy). To dzięki naszym ekspertom możemy skutecznie rozwijać kompetencje biznesowe młodych ludzi, dać im kompleksowe wsparcie w czasie studiów, a także zapewnić mocne wejście na rynek pracy. \n</p>"
      }
      buttonText="Więcej o Akademii"
      buttonLink="/akademia"
      equalSections
    />
    <SupportOptions
      heading="Jak można wspierać Program Alkantara?"
      intro={
        "<p>Sposobów na włączenie się w działania Programu Alkantara jest kilka. Każdy z nich jest cenny, bo pomaga młodym ludziom realizować marzenia o studiach i karierze w Trójmieście.</p>" +
        "<p><strong>Rozwijaj z nami program Alkantara jako:</strong></p>"
      }
      options={[
        {
          heading: "Partner",
          text:
            "<p>Wspieraj Program Alkantara jako firma partnerska oferująca płatne praktyki, staże i możliwość zatrudnienia. Stwórz przyjazne warunki do rozwoju zawodowego, współpracując z uczestnikami Akademii Alkantara w formie pełnego etatu w czasie wakacji lub części etatu w ciągu roku. Pokaż dobre praktyki biznesowe oraz skuteczne zarządzanie firmą od kuchni. Buduj z nami kompetentne młode kadry i zyskaj świetnie przygotowanych, młodych specjalistów.</p>",
        },
        {
          heading: "Mentor",
          text:
            "<p>Pokaż dobry przykład i wspieraj młodych ludzi na drodze do wymarzonej kariery. Jeśli jesteś przedsiębiorcą, managerem lub szkoleniowcem z obszaru biznesu, zostań mentorem Akademii Alkantara. Dziel się z Twoim podopiecznym swoją wiedzą, doświadczeniem i biznesowym know-how. Udzielaj rad i pomagaj szukać najlepszych sposobów na rozwój w obszarze edukacji oraz kariery.</p>",
        },
        {
          heading: "Wykładowca",
          text:
            "<p>Zostań wykładowcą Akademii Alkantara. Dołącz do zespołu przedsiębiorców, managerów zarządzających projektami oraz zespołami, szkoleniowców z obszaru biznesu i innych specjalistów. Dziel się bezcenną wiedzą i doświadczeniem w obszarach:</p>" +
            "<ul>" +
            "<li>Przedsiębiorczość i finanse</li>" +
            "<li>Organizacja projektowa i zarządzanie przez projekty</li>" +
            "<li>Kompetencje miękkie: komunikacja, współpraca i techniki negocjacji</li>" +
            "<li>Kultura: elementy kulturoznawstwa, filozofii i podstawy sztuki</li>" +
            "</ul>",
        },
        {
          heading: "Sponsor",
          text:
            "<p>Wspieraj działalność Programu Alkantara i pomóż nam tworzyć Fundusz Stypendialny dla najbardziej potrzebujących studentów. Spełniaj marzenia młodych ludzi o studiach i mieszkaniu w Trójmieście. Stwórz niezbędne warunki do nauki i rozwoju. Pomóż definiować lepszą zawodową przyszłość zdolnych studentów.</p>",
        },
      ]}
    />

    <TwoColumnSection
      heading="Razem tworzymy lepszą zawodową przyszłość młodych ludzi."
      article={
        "<h3>Zostań częścią Programu Alkantara.</h3>" +
        "<p>Porozmawiajmy o współpracy:" +
        "<br>" +
        "<strong><a href='mailto:partnerstwo@akademia-alkantara.pl'>partnerstwo@akademia-alkantara.pl</a></strong></p>"
      }
      equalSections
    />
  </Layout>
)

export default ParentPage
